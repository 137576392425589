<template>
    <div class="changelog">
        <div class="changelog-header">Application Changelog</div>

        <div class="changelog-body">
            <h2>v. 3.3.8 <span class="changelog-date">22.02.22</span></h2>
            <h3>Portfolio&Clients</h3>
            <ul>
                <li>Ux improving and fixes</li>
            </ul>
            <h3>Widgets</h3>
            <ul>
                <li>Rendering fixes</li>
                <li>Chart resizing fixes</li>
            </ul>
            <h3>Reporting</h3>
            <ul>
                <li>Report renaming fixes</li>
                <li>Ux fixes</li>
            </ul>
            <h3>Search Page</h3>
            <ul>
                <li>Search page input fixes</li>
            </ul>
            <h3>Search ISIN</h3>
            <ul>
                <li>Chart coloring, sizing, rendering fixes</li>
                <li>Added exponential line</li>
                <li>UX history mode rework</li>
                <li>Tooltip fixes</li>
                <li>Added opening instrument via tooltip</li>
            </ul>
            <h2>v. 3.3.7 <span class="changelog-date">23.12.21</span></h2>
            <h3>Portfolio</h3>
            <ul>
                <li>Expected and Fact Coupons for Bonds</li>
            </ul>
            <h3>Home Page widgets</h3>
            <ul>
                <li>Cash Movements</li>
            </ul>

            <h2>v. 3.3.6 <span class="changelog-date">20.12.21</span></h2>
            <h3>Reporting</h3>
            <ul>
                <li>PnL Report</li>
                <li>Favorite Reports</li>
            </ul>
            <h3>Search ISIN</h3>
            <ul>
                <li>Results grid</li>
            </ul>
            <h3>Risk Report widgets</h3>
            <ul>
                <li>Portfolios PnL</li>
                <li>Portfolio Drawdown</li>
            </ul>
            <h3>Application Functionality</h3>
            <ul>
                <li>Dark theme</li>
                <li>Styling, fixes & improvements</li>
            </ul>

            <h2>v. 3.3.5 <span class="changelog-date">5.12.21</span></h2>
            <h3>Portfolio</h3>
            <ul>
                <li>Expected and Fact Dividends for Equities</li>
            </ul>

            <h2>v. 3.3.4 <span class="changelog-date">22.11.21</span></h2>
            <h3>Reporting</h3>
            <ul>
                <li>Base functionality</li>
            </ul>

            <h2>v. 3.3.3 <span class="changelog-date">18.11.21</span></h2>
            <h3>Application Functionality</h3>
            <ul>
                <li>Home Page widgets. Currency Exposure widget</li>
                <li>Risk Report widgets. Top Winners widget</li>
                <li>Collateral Unblock Operations</li>
                <li>Styling, fixes & improvements</li>
            </ul>

            <h2>v. 3.3.2 <span class="changelog-date">12.11.21</span></h2>
            <h3>Application Functionality</h3>
            <ul>
                <li>Collateral Block Operations</li>
                <li>Styling, fixes & improvements</li>
            </ul>

            <h2>v. 3.3.1 <span class="changelog-date">01.11.21</span></h2>
            <h3>Application Functionality</h3>
            <ul>
                <li>Styling, fixes & improvements</li>
                <li>User Profile</li>
                <li>Backend Optimization</li>
            </ul>

            <h2>v. 3.3.0 <span class="changelog-date">15.10.21</span></h2>
            <h3>Application Functionality</h3>
            <ul>
                <li>Styling, fixes & improvements</li>
            </ul>

            <h2>v. 3.2.9 <span class="changelog-date">15.10.21</span></h2>
            <h3>Portfolio</h3>
            <ul>
                <li>Import from Excel File</li>
                <li>Cash Flow Calculations</li>
            </ul>

            <h2>v. 3.2.8 <span class="changelog-date">04.10.21</span></h2>
            <h3>Client</h3>
            <ul>
                <li>Profile Tab</li>
            </ul>
            <h2>v. 3.2.7 <span class="changelog-date">02.10.21</span></h2>
            <h3>Portfolio</h3>
            <ul>
                <li>Risk-Free Rates and Benchmarks</li>
            </ul>

            <h2>v. 3.2.6 <span class="changelog-date">23.09.21</span></h2>
            <h3>Portfolio</h3>
            <ul>
                <li>Options operations</li>
                <li>TWR and MWR Analytics</li>
                <li>Fixes and improvements</li>
            </ul>

            <h2>v. 3.2.5 <span class="changelog-date">23.09.21</span></h2>
            <h3>Portfolio</h3>
            <ul>
                <li>Single Portfolio Page</li>
            </ul>

            <h2>v. 3.2.4 <span class="changelog-date">21.09.21</span></h2>
            <h3>Portfolio</h3>
            <ul>
                <li>Equity operations</li>
            </ul>

            <h2>v. 3.2.3 <span class="changelog-date">21.09.21</span></h2>
            <h3>Portfolio</h3>
            <ul>
                <li>Fixed Income operations</li>
            </ul>

            <h2>v. 3.2.2 <span class="changelog-date">20.09.21</span></h2>
            <h3>Portfolio</h3>
            <ul>
                <li>Cash operations</li>
                <li>Future operations</li>
            </ul>

            <h2>v. 3.2.1 <span class="changelog-date">20.09.21</span></h2>
            <h3>Client Page</h3>
            <ul>
                <li>Portfolio Operations History Improvement</li>
            </ul>

            <h2>v. 3.1.4 <span class="changelog-date">10.09.21</span></h2>
            <h3>Client Page</h3>
            <ul>
                <li>Portfolio Updates and Improvements</li>
            </ul>
            <h3>Dictionaries</h3>
            <ul>
                <li>Filters Improvements</li>
            </ul>

            <h2>v. 3.1.3 <span class="changelog-date">09.09.21</span></h2>
            <h3>Client Page</h3>
            <ul>
                <li>Portfolios</li>
                <li>Bonds view in Portfolios</li>
            </ul>

            <h2>v. 3.1.2 <span class="changelog-date">08.09.21</span></h2>
            <h3>Client Page</h3>
            <ul>
                <li>Portfolio Summary</li>
            </ul>

            <h2>v. 3.1.1 <span class="changelog-date">27.08.21</span></h2>
            <h3>Home Page & Risk Report Page Widgets</h3>
            <ul>
                <li>Widgets Improvement</li>
            </ul>

            <h2>v. 3.1.0 <span class="changelog-date">22.08.21</span></h2>
            <h3>Home Page & Risk Report Page Widgets</h3>
            <ul>
                <li>Home Page & Risk Report Page Widgets Functionality & Styling</li>
                <li>Asset Classes Widget</li>
            </ul>

            <h2>v. 3.0.0 <span class="changelog-date">13.08.21</span></h2>
            <h3>Application Functionality</h3>
            <ul>
                <li>Application Styling</li>
                <li>Tabs Routing</li>
                <li>Tabs History</li>
                <li>Dictionaries Functionality & Styling</li>
            </ul>

        </div>
    </div>
</template>

<script>
export default {}
</script>
